// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

:root {
	--ion-grid-width-md: 600px;
	--ion-grid-width-lg: 600px;
	--ion-grid-width-xl: 600px;

	ion-content {
		--background: rgb(246, 246, 246);
	}
	
	ion-card {
		--background: rgb(246, 246, 246);
	}
	
	ion-list {
		--background: rgb(246, 246, 246);
	}	
}

@media (min-width: 700px) {
	.scroll-content {
	  overflow-y:auto;
	}
 }

#highlightNavLink {
	color: var(--ion-color-danger);
}

ion-modal.splash .modal-wrapper {
	.content { 
		 background-color: #318eac;
	}
	
	background-color: #318eac;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

ion-menu ion-header .toolbar-title {
	font-family: ChunkFive;
	color: #19a5da;
	font-size: 2.5em;
}

@font-face {
	font-family: 'ChunkFive';
	src: url('./assets/fonts/chunkfive/Chunkfive-webfont.eot');
	src: url('./assets/fonts/chunkfive/Chunkfive-webfont.eot?#iefix') format('embedded-opentype'),
		  url('./assets/fonts/chunkfive/Chunkfive-webfont.woff') format('woff'),
		  url('./assets/fonts/chunkfive/Chunkfive-webfont.ttf') format('truetype'),
		  url('./assets/fonts/chunkfive/Chunkfive-webfont.svg#ChunkFiveRegular') format('svg');
	font-weight: normal;
	font-style: normal;

}

#notifications-badge {
  position: absolute;
  top: 0px;
  right: 0px;
}
