// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #09c;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;
	
	/** secondary **/
	--ion-color-secondary: #32db64;
	--ion-color-secondary-rgb: 12, 209, 232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #32db64;
	--ion-color-secondary-tint: #32db64;
 
	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112, 68, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;
 
	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;
 
	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;
 
	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;
 
	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;
 
	/** medium **/
	--ion-color-medium: #dadada;
	--ion-color-medium-rgb: 218,218,218;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #c0c0c0;
	--ion-color-medium-tint: #dedede;
 
	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** lightgray **/
	.ion-color-lightgray {
		--ion-color-base: #c3c3c3;
		--ion-color-base-rgb: 244, 244, 244;
		--ion-color-contrast: #ffff;
		--ion-color-contrast-rgb: 255, 255, 255;
		--ion-color-shade: #d7d8da;
		--ion-color-tint: #f5f6f9;
	}

	/** brown **/
	.ion-color-brown {	
		--ion-color-base: #cb5f1f;
		--ion-color-base-rgb: 244, 244, 244;
		--ion-color-contrast: #ffff;
		--ion-color-contrast-rgb: 255, 255, 255;
		--ion-color-shade: #d7d8da;
		--ion-color-tint: #f5f6f9;
	}

	/** orange **/
	.ion-color-orange {
		--ion-color-base: #ff8c00;
		--ion-color-base-rgb: 244, 244, 244;
		--ion-color-contrast: #ffff;
		--ion-color-contrast-rgb: 255, 255, 255;
		--ion-color-shade: #d7d8da;
		--ion-color-tint: #f5f6f9;
	}
 }